import React from 'react'
import { graphql } from 'gatsby'
import $ from '@gizt/selector'

import Layout from '../../components/layout'
import PageTitle from '../../components/page-title'
import ContactForm from './contact-form'
import MetaTitle from '../../components/meta-title'
import MetaDescription from '../../components/meta-description'

export default function ContactPage ({ pageContext, data: queryData }) {
  const {
    isSuccessPage = false,
    title,
    introTextHtml,
    successMessage
  } = pageContext
  const imageUrl = $('contactDecoration.edges[0].node.publicURL', queryData)

  return (
    <>
      <Layout>
        <MetaTitle>Contacteer Fotografe</MetaTitle>
        <MetaDescription>
          Contacteer hier Laurence Verheijen om je fotoshoot te boeken. Laurence
          fotografeert in regio Antwerpen, maar fotoshoots zijn mogelijk over
          heel Vlaanderen.
        </MetaDescription>

        <div className='flex min-h-full'>
          <div className='w-full lg:w-1/2'>
            <div className='container-wide pt-40'>
              {isSuccessPage ? (
                <PageTitle className='mb-56'>Bedankt voor je bericht</PageTitle>
              ) : (
                <PageTitle className='mb-56'>{title}</PageTitle>
              )}
            </div>

            <div className='max-w-600 mx-auto px-16 pb-40'>
              {isSuccessPage ? (
                <>
                  <div
                    className='markdown-content mb-24'
                    dangerouslySetInnerHTML={{ __html: successMessage }}
                  />
                </>
              ) : (
                <>
                  <div
                    className='markdown-content mb-24'
                    dangerouslySetInnerHTML={{ __html: introTextHtml }}
                  />
                  <ContactForm />
                </>
              )}
            </div>
          </div>
          <div
            className='hidden lg:block lg:w-1/2 bg-black bg-cover bg-center'
            style={{
              backgroundImage: `url(${imageUrl})`
            }}
          />
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ContactDecoration($image: String!) {
    contactDecoration: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: $image }
      }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`
