import React, { Component } from 'react'
import cx from 'classnames'

export default class ContactForm extends Component {
  render () {
    const formFieldClasses = cx(
      'font-sans font-normal text-16 md:text-14 block border border-solid border-gray hover:border-gray-dark focus:border-gray-dark',
      'w-full p-8 mb-16 outline-none rounded-none shadow-none appearance-none trans'
    )

    return (
      <form
        action='/contact/success'
        name='contact'
        method='POST'
        data-netlify='true'
        netlify-honeypot='honeypot'
      >
        <input className='hidden' value='contact' name='form-name' readOnly />

        <div>
          <label htmlFor='name'>Naam</label>
          <input
            id='name'
            name='name'
            className={cx(formFieldClasses, 'min-h-40')}
            type='text'
            required
          />
        </div>

        <div>
          <label htmlFor='email'>Email</label>
          <input
            id='email'
            name='email'
            type='email'
            className={cx(formFieldClasses, 'min-h-40')}
            required
          />
        </div>

        <div>
          <label htmlFor='message'>Bericht</label>
          <textarea
            id='message'
            name='message'
            className={cx(formFieldClasses, 'min-h-240')}
            required
          />
        </div>

        <div className='hidden'>
          <label>
            <input name='honeypot' />
          </label>
        </div>

        <button className='font-sans text-14 bg-gray-darkest hover:bg-black focus:bg-black text-white border-0 block min-h-40 text-center w-full trans trans-bg cursor-pointer'>
          Verzenden
        </button>
      </form>
    )
  }
}
